import axios from 'axios';
import { SERVER_API_URL } from '../configs/urls';

const axiosObj = axios.create({
	baseURL: SERVER_API_URL
});

const submitReview = (payload) => axiosObj.post(`submitreview`, payload);

const isReviewFilled = (id) => axiosObj.get(`isreviewfilled`, { params: { id } });

const publicApi = {
	submitReview,
	isReviewFilled
};

export default publicApi;
