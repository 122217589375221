import Register from 'views/auth/Register.js';
import Login from 'views/auth/Login.js';
import UsersPage from 'views/Dashboard/UsersPage';
import CustomerReview from 'views/Dashboard/CustomerReviews';
import AggregateReviewPage from 'views/Dashboard/AggregateReviewPage';

var routes = [
	{
		path: '/index',
		name: 'Dashboard',
		icon: 'ni ni-tv-2 text-primary',
		component: AggregateReviewPage,
		layout: '/admin'
	},
	{
		path: '/customers',
		name: 'Customers',
		icon: 'ni ni-single-02 text-blue',
		component: UsersPage,
		layout: '/admin'
	},
	{
		path: '/reviews',
		name: 'Reviews',
		icon: 'ni ni-single-copy-04 text-red',
		component: CustomerReview,
		layout: '/admin'
	},

	{
		path: '/login',
		name: 'Login',
		icon: 'ni ni-key-25 text-info',
		component: Login,
		layout: '/auth',
		isMenu: false
	},
	{
		path: '/register',
		name: 'Register',
		icon: 'ni ni-circle-08 text-pink',
		component: Register,
		layout: '/auth',
		isMenu: false
	}
];
export default routes;
