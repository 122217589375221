import Header from 'components/Headers/Header';
import { useEffect, useState } from 'react';
import {
	Button,
	Card,
	CardFooter,
	CardHeader,
	Col,
	Container,
	Modal,
	Row,
	Spinner,
	Table
} from 'reactstrap';
import { FaPencilAlt } from 'react-icons/fa';
import { FcInfo } from 'react-icons/fc';
import { MdDeleteForever, MdSend } from 'react-icons/md';
import OnlyHeader from 'components/Headers/OnlyHeader';
import adminApi from 'apis/admin-apis';
import { useDispatch, useSelector } from 'react-redux';
import CustomerTable from 'components/UsersPage/CustomerTable';
import { setCustomerLoading } from 'redux/customersSlice';
import { setCustomer } from 'redux/customersSlice';
import useAuth from 'hooks/useAuth';
import CreateCustomerModal from 'components/UsersPage/CreateCustomerModal';
import { toggleCreateNewCustomerModal } from 'redux/modalsSlice';
import UpdateCustomerModal from 'components/UsersPage/UpdateCustomerModal';
import DeleteCustomerModal from 'components/UsersPage/DeleteCustomerModal';
import SendReviewModal from 'components/UsersPage/SendReviewModal';
import '../../styles/UsersPage.css';

const UsersPage = () => {
	useAuth();
	const dispatch = useDispatch();

	const { authToken } = useSelector((state) => state.auth);
	const { customersLoading } = useSelector((state) => state.customers);

	const {
		updateCustomerModal,
		createNewCustomerModal,
		deleteCustomerModal,
		sendReviewToCustomerModal
	} = useSelector((state) => state.modals);

	return (
		<>
			<OnlyHeader />
			<Container fluid className="mt--7">
				<Row>
					<Col>
						<Card className="shadow">
							<CardHeader>
								<h3>Customers</h3>
							</CardHeader>

							<Row style={{ justifyContent: 'flex-end' }}>
								<Button
									color="primary"
									className="m-4"
									onClick={() => dispatch(toggleCreateNewCustomerModal())}
								>
									New Customer
								</Button>
							</Row>

							{customersLoading ? <Spinner /> : <CustomerTable />}

							<CardFooter></CardFooter>
						</Card>
					</Col>
				</Row>
				{createNewCustomerModal && <CreateCustomerModal />}
				{updateCustomerModal && <UpdateCustomerModal />}
				{deleteCustomerModal && <DeleteCustomerModal />}
				{sendReviewToCustomerModal && <SendReviewModal />}
			</Container>
		</>
	);
};

export default UsersPage;
