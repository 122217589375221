import axios from 'axios';
import { SERVER_API_URL } from '../configs/urls';

const axiosObj = axios.create({
	baseURL: SERVER_API_URL + 'admin'
});

const getAllCustomers = (authToken) =>
	axiosObj.get(`/customers`, { headers: { AuthToken: authToken } });

const getCustomerById = (customerId, authToken) =>
	axiosObj.get(`/customer`, {
		headers: { AuthToken: authToken },
		params: { customerId: customerId }
	});

const createCustomer = (payload, authToken) =>
	axiosObj.post(`/customer`, payload, { headers: { AuthToken: authToken } });

const updateCustomer = (customerId, payload, authToken) =>
	axiosObj.put(`/customer`, payload, {
		headers: { AuthToken: authToken },
		params: { customerId: customerId }
	});

const deleteCustomer = (customerId, authToken) =>
	axiosObj.delete(`/customer`, { headers: { AuthToken: authToken }, params: { customerId } });

const createReview = (payload, authToken) =>
	axiosObj.post(`/review`, payload, { headers: { AuthToken: authToken } });

const customerReviews = (customerId, authToken) =>
	axiosObj.get(`/customerreviews`, { headers: { AuthToken: authToken }, params: { customerId } });

const getAllReviews = (authToken) =>
	axiosObj.get(`/reviews`, { headers: { AuthToken: authToken } });

const sendReminderEmail = (payload, authToken) =>
	axiosObj.post(`/reminderemail`, payload, { headers: { AuthToken: authToken } });

const adminApi = {
	getAllCustomers,
	getCustomerById,
	createCustomer,
	updateCustomer,
	deleteCustomer,

	createReview,
	customerReviews,
	getAllReviews,
	sendReminderEmail
};

export default adminApi;
