// review: name, created, isFilled, fillTime, sender, rating, ratingText

import adminApi from 'apis/admin-apis';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Spinner
} from 'reactstrap';
import { toggleSendReviewToCustomerModal } from 'redux/modalsSlice';

const SendReviewModal = () => {
	const dispatch = useDispatch();
	const { authToken, email } = useSelector((state) => state.auth);
	const { sendReviewToCustomerModal } = useSelector((state) => state.modals);
	const { selectedCustomer, selectedCustomerId } = useSelector((state) => state.customers);

	const [surveyName, setSurveyName] = useState('');
	const [loading, setLoading] = useState(false);
	const [surveyNameInvalid, setSurveyNameInvalid] = useState(false);

	const [emailBodyText, setEmailBodyText] =
		useState(`Wir bei RIT Services sind ständig bestrebt, unsere Software und Dienstleistungen zu verbessern, um Ihren Bedürfnissen besser gerecht zu werden. Ihr Feedback ist für uns sehr wertvoll, und wir würden es sehr schätzen, wenn Sie sich einen Moment Zeit nehmen könnten, um uns Ihre Gedanken mitzuteilen. 
Ihre Meinung ist uns wichtig, und Ihre Bewertung kann uns helfen zu verstehen, was wir richtig machen und wo wir uns verbessern können.`);

	const handleSendReview = () => {
		// TODO: OnKeyDown Enter on all inputs.
		setLoading(true);
		if (surveyName.trim() !== '') {
			adminApi
				.createReview(
					{
						email: selectedCustomer.email,
						name: surveyName,
						sender: email,
						customerId: selectedCustomer.customerId,
						emailBodyText: emailBodyText
					},
					authToken
				)
				.then((response) => {
					setLoading(false);
					dispatch(toggleSendReviewToCustomerModal());
					toast.success('Email Sent!', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light'
					});
				})
				.catch((err) => {
					setLoading(false);
				});
		} else {
			setSurveyNameInvalid(true);
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleSendReview();
		}
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				isOpen={sendReviewToCustomerModal}
				toggle={() => {
					dispatch(toggleSendReviewToCustomerModal());
				}}
			>
				<div className="modal-header align-items-center">
					<h4 className="modal-title">
						Send Survey to {selectedCustomer && selectedCustomer.name}
					</h4>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => dispatch(toggleSendReviewToCustomerModal())}
					>
						<span aria-hidden={true}>x</span>
					</button>
				</div>
				<ModalBody>
					<Form>
						<FormGroup>
							<Label>Name of Survey</Label>
							<Input
								type="text"
								value={surveyName}
								onChange={(e) => setSurveyName(e.target.value)}
								invalid={surveyNameInvalid}
								onKeyDown={handleKeyPress}
							/>
							<FormFeedback>Name is required!</FormFeedback>
						</FormGroup>

						<FormGroup>
							<Label>Email Body Text</Label>
							<Input
								type="textarea"
								style={{ height: '150px' }}
								value={emailBodyText}
								onChange={(e) => setEmailBodyText(e.target.value)}
							/>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" disabled={loading} onClick={handleSendReview}>
						{loading ? <Spinner size={'sm'} /> : 'Send'}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default SendReviewModal;
