import { Button, Col, Row } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setRatingSelected } from 'redux/ratingSlice';

const RatingsComponent = () => {
	const emojis = {
		bad: (
			<img
				src={require('../../assets/img/icons/common/emoji6-0.svg').default}
				alt="emoji"
				// width={'40px'}
			/>
		),
		mid: (
			<img src={require('../../assets/img/icons/common/emoji-7-8.svg').default} alt="emoji" />
		),
		high: (
			<img src={require('../../assets/img/icons/common/emoji9-10.svg').default} alt="emoji" />
		)
	};

	const ratings = [
		{
			value: 1,
			emoji: emojis.bad,
			reviewFeedback: 'auf keinen Fall!'
		},
		{
			value: 2,
			emoji: emojis.bad,
			reviewFeedback: 'sehr unwahrscheinlich'
		},
		{
			value: 3,
			emoji: emojis.bad,
			reviewFeedback: 'unwahrscheinlich'
		},
		{
			value: 4,
			emoji: emojis.bad,
			reviewFeedback: 'eher nicht'
		},
		{
			value: 5,
			emoji: emojis.bad,
			reviewFeedback: 'müsste ich abwägen'
		},
		{
			value: 6,
			emoji: emojis.bad,
			reviewFeedback: 'nicht uneingeschränkt'
		},
		{
			value: 7,
			emoji: emojis.mid,
			reviewFeedback: 'ich denke schon'
		},
		{
			value: 8,
			emoji: emojis.mid,
			reviewFeedback: 'ja, würde ich'
		},
		{
			value: 9,
			emoji: emojis.high,
			reviewFeedback: 'sehr wahrscheinlich'
		},
		{
			value: 10,
			emoji: emojis.high,
			reviewFeedback: 'auf jeden Fall, immer!'
		}
	];

	const isXsScreen = useMediaQuery({ maxWidth: 575 }); // xs screen size
	const isMdScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 }); // md screen size

	const dispatch = useDispatch();

	const { ratingSelected, ratingText } = useSelector((state) => state.rating);
	const [hovered, setHovered] = useState(null);
	const [scoreText, setScoreText] = useState('');

	return (
		<>
			<Row className="mt-4 mb-5 d-flex justify-content-center">
				{scoreText ? <h2 style={{ color: 'white' }}>{scoreText}</h2> : <h2>&nbsp;</h2>}
			</Row>
			<Row className="mt-4 mb-5 d-flex justify-content-center">
				{ratings.map((rating) => {
					return isXsScreen ? (
						<Col
							xs="12"
							className={`d-flex justify-content-center ${
								rating.value === parseInt(ratingSelected)
									? 'rating-button-active'
									: ''
							}`}
							key={rating.value.toString()}
						>
							<Button
								className="rating-button"
								style={{
									width: '85%',
									paddingTop: '10px',
									paddingBottom: '10px',
									marginTop: '10px',
									backgroundColor: '#272E34',
									color: 'white',
									border: 'none'
								}}
								onClick={() => dispatch(setRatingSelected(rating.value.toString()))}
							>
								<span style={{ fontSize: '20px' }}>{rating.value}</span>{' '}
								<span>{rating.reviewFeedback}</span>
							</Button>
						</Col>
					) : (
						<Col md="1">
							<Button
								className={`rating-button ${
									rating === parseInt(ratingSelected)
										? 'rating-button-active'
										: ''
								}`}
								style={{
									borderRadius: '50%',
									width: '70px',
									height: '70px',
									backgroundColor: '#272E34',
									color: 'white',
									border: 'none',
									marginTop: '10px',
									padding: '10px 0px 10px 0px'
								}}
								onClick={(e) => {
									console.log(e);
									dispatch(setRatingSelected(rating.value.toString()));
								}}
								key={rating.value.toString()}
								onMouseEnter={(e) => {
									setHovered(parseInt(e.target.innerText));
									setScoreText(
										ratings[parseInt(e.target.innerText) - 1].reviewFeedback
									);
								}}
								onMouseLeave={(e) => {
									setHovered(null);
									setScoreText('');
								}}
							>
								{rating.value === parseInt(ratingSelected) ||
								hovered === rating.value
									? rating.emoji
									: rating.value}
							</Button>
						</Col>
					);
				})}
			</Row>
		</>
	);
};

export default RatingsComponent;
