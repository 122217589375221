import adminApi from 'apis/admin-apis';
import { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { MdDeleteForever, MdSend } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Button, Col, Row, Spinner, Table } from 'reactstrap';
import { setSelectedCustomerId } from 'redux/customersSlice';
import { setSelectedCustomer } from 'redux/customersSlice';
import { setCustomer } from 'redux/customersSlice';
import { setCustomerLoading } from 'redux/customersSlice';
import { toggleSendReviewToCustomerModal } from 'redux/modalsSlice';
import { toggleDeleteCustomerModal } from 'redux/modalsSlice';
import { toggleUpdateCustomerModal } from 'redux/modalsSlice';

const CustomerTable = () => {
	const dispatch = useDispatch();
	const { customers, customersLoading } = useSelector((state) => state.customers);
	const { authToken } = useSelector((state) => state.auth);
	const { createNewCustomerModal, updateCustomerModal, deleteCustomerModal } = useSelector(
		(state) => state.modals
	);

	const navigate = useHistory();

	useEffect(() => {
		dispatch(setCustomerLoading(true));
		adminApi
			.getAllCustomers(authToken)
			.then((res) => {
				dispatch(setCustomer(res.data.customers));
				dispatch(setCustomerLoading(false));
			})
			.catch((err) => {
				dispatch(setCustomerLoading(false));
			});
	}, [createNewCustomerModal, updateCustomerModal, deleteCustomerModal]);

	return (
		<>
			{customersLoading ? (
				<Row className="d-flex" style={{ justifyContent: 'center' }}>
					<Spinner />
				</Row>
			) : customers.length === 0 ? (
				<Row className="d-flex" style={{ justifyContent: 'center' }}>
					<h2>No Data!</h2>
				</Row>
			) : (
				<Table responsive>
					<thead className="thead-light">
						<tr>
							<th data-testid="table-column" scope="col">
								Name
							</th>
							<th data-testid="table-column" scope="col">
								Email
							</th>
							<th data-testid="table-column" scope="col" style={{ width: '15%' }} />
						</tr>
					</thead>
					<tbody>
						{customers.map((customer, index) => {
							return (
								<tr
									key={`${index + customer.customerId}`}
									// onClick={() =>
									// 	navigate.push(
									// 		`/admin/reviews?customerId=${customer.customerId}`,
									// 		{ replace: true }
									// 	)
									// }
									className="customer-row"
								>
									<td
										onClick={() =>
											navigate.push(
												`/admin/reviews?customerId=${customer.customerId}`,
												{ replace: true }
											)
										}
									>
										<span></span>
										{customer.name}
									</td>
									<td
										onClick={() =>
											navigate.push(
												`/admin/reviews?customerId=${customer.customerId}`,
												{ replace: true }
											)
										}
									>
										{customer.email}
									</td>
									<td>
										<Button
											className="btn-icon btn-2"
											color="primary"
											type="button"
											// style={{ padding: '3px 5px 3px 5px' }}
											onClick={() => {
												dispatch(
													setSelectedCustomerId(customer.customerId)
												);
												dispatch(toggleUpdateCustomerModal());
											}}
										>
											<FaPencilAlt fontSize={'20px'} />
										</Button>
										<Button
											className="btn-icon btn-2"
											color="primary"
											type="button"
											// style={{
											// 	backgroundColor: '#F44336',
											// 	padding: '3px 5px 3px 5px'
											// }}
											onClick={() => {
												dispatch(
													setSelectedCustomerId(customer.customerId)
												);
												dispatch(setSelectedCustomer(customer));
												dispatch(toggleSendReviewToCustomerModal());
											}}
										>
											<MdSend fontSize={'20px'} />
										</Button>
										<Button
											className="btn-icon btn-2"
											color="red"
											type="button"
											style={{
												backgroundColor: '#F44336'
												// padding: '3px 5px 3px 5px'
											}}
											onClick={() => {
												dispatch(
													setSelectedCustomerId(customer.customerId)
												);
												dispatch(setSelectedCustomer(customer));
												dispatch(toggleDeleteCustomerModal());
											}}
										>
											<MdDeleteForever color="white" fontSize={'20px'} />
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			)}
		</>
	);
};

export default CustomerTable;
