// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Spinner
} from 'reactstrap';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { setAuthToken, setAuthEmail } from 'redux/authSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const Login = () => {
	const navigate = useHistory();
	const dispatch = useDispatch();

	// const []
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [loading, setLoading] = useState(false);

	const signInWithEmailPass = async () => {
		setLoading(true);
		const auth = getAuth();
		signInWithEmailAndPassword(auth, email.trim(), password.trim())
			.then(async (res) => {
				let token = await res.user.getIdToken(true);
				console.log('Email: ', res.user.email);
				dispatch(setAuthEmail(res.user.email));
				await saveToken();

				navigate.push('/admin/index', { replace: true });
				setLoading(false);
			})
			.catch((err) => {
				console.log('Wrong Email or Password!');
				toast.error('Wrong Email or Password!', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light'
				});
				console.log(err);
				setLoading(false);
			});
	};

	const saveToken = async () => {
		const auth = getAuth();
		var authToken = await auth.currentUser.getIdToken(true);
		dispatch(setAuthToken(authToken));
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			signInWithEmailPass();
		}
	};

	return (
		<>
			<Col lg="5" md="7">
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="text-center text-muted mb-4">
							<small>Sign in</small>
						</div>
						<Form role="form">
							<FormGroup className="mb-3">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Email"
										type="email"
										autoComplete="new-email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Password"
										type="password"
										autoComplete="new-password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										onKeyDown={handleKeyPress}
									/>
								</InputGroup>
							</FormGroup>

							<div className="text-center">
								<Button
									disabled={loading}
									className="my-4"
									color="primary"
									type="button"
									onClick={signInWithEmailPass}
								>
									{loading ? <Spinner size="sm" /> : 'Sign in'}
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
				<Row className="mt-3">
					{/* <Col xs="6">
						<a className="text-light" href="#pablo" onClick={(e) => e.preventDefault()}>
							<small>Forgot password?</small>
						</a>
					</Col> */}
					<Col className="text-right" xs="12">
						<a className="text-light" href="/auth/register">
							<small>Create new account</small>
						</a>
					</Col>
				</Row>
			</Col>
		</>
	);
};

export default Login;
