import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		authToken: '',
		email: ''
	},
	reducers: {
		setAuthToken: (state, action) => {
			state.authToken = action.payload;
		},
		clearAuthToken: (state) => {
			state.authToken = '';
		},
		setAuthEmail: (state, action) => {
			state.email = action.payload;
		},
		clearAuthEmail: (state) => {
			state.email = '';
		}
	}
});
export const { setAuthToken, clearAuthToken, setAuthEmail, clearAuthEmail } = authSlice.actions;
export default authSlice.reducer;
