import Panel from 'components/BasicUIElements/Panel';
import { Button, Card, CardBody, Col, Input, Row } from 'reactstrap';
import RatingsComponent from './RatingsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { setRatingText } from 'redux/ratingSlice';
import { submitReview } from 'redux/ratingSlice';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect, useState } from 'react';
import { uniq } from 'lodash';

const InputReview = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { ratingText } = useSelector((state) => state.rating);
	const [id, setId] = useState('');

	useEffect(() => {
		var uniqueId = location.pathname.replace('/review/', '');
		setId(uniqueId);
		console.log(uniqueId);
	});

	return (
		<>
			<Row className="d-flex justify-content-center">
				<Col xs="12">
					<Panel bg="#1F2630">
						<Row className="justify-content-center mt-4">
							<Col xs="12">
								<h1 style={{ color: 'white', fontFamily: 'Bricolage Grotesque' }}>
									Wie haben wir abgeschnitten?
								</h1>
							</Col>
						</Row>
						<Row className="justify-content-center mt-5">
							<Col xs="12">
								<p style={{ color: 'white', fontFamily: 'Bricolage Grotesque' }}>
									Bitte lassen Sie uns wissen, wie wir abgeschnitten haben und
									geben Sie uns Ihr wertvolles Feedback geben. Ihr Feedback ist
									uns sehr wichtig und hilft uns bei der Verbesserung unser
									Angebot zu verbessern!
								</p>
							</Col>
						</Row>

						<RatingsComponent />

						<Row className="justify-content-center">
							<Col xs="11">
								<Input
									type="textarea"
									style={{ height: '150px' }}
									value={ratingText}
									onChange={(e) => dispatch(setRatingText(e.target.value))}
									placeholder="Bitte teilen Sie Ihre Meinung hier mit ...."
								/>
							</Col>
						</Row>

						<Row className="justify-content-center mt-5 mb-4">
							<Col xs="10" md="4" className="justify-content-center">
								<Button
									style={{
										backgroundColor: '#FD7613',
										color: 'white',
										borderRadius: '25px',
										width: '100%',
										border: 'none'
									}}
									onClick={() => dispatch(submitReview(id))}
								>
									einreichen
								</Button>
							</Col>
						</Row>
					</Panel>
				</Col>
			</Row>
		</>
	);
};

export default InputReview;
