import adminApi from 'apis/admin-apis';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	Row,
	Spinner
} from 'reactstrap';
import { toggleUpdateCustomerModal } from 'redux/modalsSlice';
import { toggleCreateNewCustomerModal } from 'redux/modalsSlice';

const UpdateCustomerModal = () => {
	const dispatch = useDispatch();
	const { updateCustomerModal } = useSelector((state) => state.modals);
	const { authToken } = useSelector((state) => state.auth);
	const { selectedCustomerId } = useSelector((state) => state.customers);

	const [updateCustomerLoading, setUpdateCustomerLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameInvalid, setNameInvalid] = useState(false);

	const [email, setEmail] = useState('');
	const [emailInvalid, setEmailInvalid] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		adminApi
			.getCustomerById(selectedCustomerId, authToken)
			.then((response) => {
				setEmail(response.data.customer.email);
				setName(response.data.customer.name);
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
			});
	}, []);

	const submitUpdateCustomer = async () => {
		setUpdateCustomerLoading(true);
		validateUpdateCustomerInput();
		if (email.trim() !== '' && name.trim() !== '') {
			adminApi
				.updateCustomer(selectedCustomerId, { customer: { name, email } }, authToken)
				.then((response) => {
					dispatch(toggleUpdateCustomerModal());
					setUpdateCustomerLoading(false);
				})
				.catch((err) => {
					setUpdateCustomerLoading(false);
				});
		} else {
			setUpdateCustomerLoading(false);
		}
	};

	const validateUpdateCustomerInput = () => {
		if (email.trim() === '') {
			setEmailInvalid(true);
		} else {
			setEmailInvalid(false);
		}

		if (name.trim() === '') {
			setNameInvalid(true);
		} else {
			setNameInvalid(false);
		}
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				isOpen={updateCustomerModal}
				toggle={() => {
					dispatch(toggleCreateNewCustomerModal());
				}}
			>
				<div className="modal-header align-items-center">
					<h4 className="modal-title">Edit Customer</h4>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => dispatch(toggleUpdateCustomerModal())}
					>
						<span aria-hidden={true}>x</span>
					</button>
				</div>
				{loading ? (
					<Row className="justify-content-center">
						<Spinner />
					</Row>
				) : (
					<ModalBody>
						<Form>
							<FormGroup>
								<Label>Name</Label>
								<Input
									type="text"
									invalid={nameInvalid}
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
								<FormFeedback>Name required!</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label>Email</Label>
								<Input
									type="email"
									invalid={emailInvalid}
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<FormFeedback>Email required!</FormFeedback>
							</FormGroup>
						</Form>
					</ModalBody>
				)}

				<ModalFooter>
					<Button color="primary" onClick={submitUpdateCustomer}>
						{updateCustomerLoading ? <Spinner size="xs" /> : 'Update'}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default UpdateCustomerModal;
