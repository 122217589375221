// reactstrap components
import {
	Button,
	Card,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Spinner
} from 'reactstrap';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { createUser } from 'redux/authSlice';
import { setAuthToken } from 'redux/authSlice';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAuthEmail } from 'redux/authSlice';

const Register = () => {
	const dispatch = useDispatch();
	const navigate = useHistory();

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event) => {
		event.preventDefault();
		if (validateEmail() && validatePassword()) {
			console.log('Signed up!');
			await signupWithEmailPass();
		}
	};

	const signupWithEmailPass = async () => {
		setLoading(true);
		const auth = getAuth();
		createUserWithEmailAndPassword(auth, email.trim(), password.trim())
			.then(async (userCredential) => {
				await saveToken();
				dispatch(setAuthEmail(userCredential.user.email));
				navigate.push('/admin/index', { replace: true });
				setLoading(false);
			})
			.catch((error) => {
				if (error.code === 'auth/email-already-in-use') {
					toast.error('Email already in use!', {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light'
					});
				} else {
				}
				setLoading(false);
			});
	};

	const saveToken = async () => {
		const auth = getAuth();
		var authToken = await auth.currentUser.getIdToken(true);
		dispatch(setAuthToken(authToken));
	};

	const validateEmail = () => {
		if (email.trim() === '') {
			// setEmailError('Email is required');
			toast.error('Email is Required!', {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light'
			});
			return false;
		} else if (!/\S+@\S+\.\S+/.test(email)) {
			// setEmailError('Invalid email address');
			toast.error('Invalid Email ', {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light'
			});
			return false;
		} else {
			// setEmailError(null);
			return true;
		}
	};

	const validatePassword = () => {
		const passwordRegex =
			/^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/;

		if (password.trim() === '') {
			// setPasswordError('Password is required');
			toast.error('Password Required!', {
				position: 'bottom-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light'
			});
			return false;
		} else {
			if (!passwordRegex.test(password.trim())) {
				toast.error(
					'Password should be greater than 8 characters, alphanumeric, must contain special charcters and must have upper and lowercase letter!',
					{
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light'
					}
				);
				return false;
			} else {
				return true;
			}
		}
	};

	return (
		<>
			<Col lg="6" md="8">
				<Card className="bg-secondary shadow border-0">
					<CardBody className="px-lg-5 py-lg-5">
						<div className="text-center text-muted mb-4">
							<small>Sign up</small>
						</div>
						<Form role="form">
							<FormGroup>
								<InputGroup className="input-group-alternative mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-hat-3" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Name"
										type="text"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative mb-3">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Email"
										type="email"
										autoComplete="new-email"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</InputGroup>
							</FormGroup>
							<FormGroup>
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-lock-circle-open" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Password"
										type="password"
										autoComplete="new-password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
								</InputGroup>
							</FormGroup>
							{/* <Row className="my-4">
								<Col xs="12">
									<div className="custom-control custom-control-alternative custom-checkbox">
										<input
											className="custom-control-input"
											id="customCheckRegister"
											type="checkbox"
										/>
										<label
											className="custom-control-label"
											htmlFor="customCheckRegister"
										>
											<span className="text-muted">
												I agree with the{' '}
												<a
													href="#pablo"
													onClick={(e) => e.preventDefault()}
												>
													Privacy Policy
												</a>
											</span>
										</label>
									</div>
								</Col>
							</Row> */}
							<div className="text-center">
								<Button
									disabled={loading}
									className="mt-4"
									color="primary"
									type="button"
									onClick={handleSubmit}
								>
									{loading ? <Spinner size={'sm'} /> : 'Register'}
								</Button>
							</div>
						</Form>
					</CardBody>
				</Card>
			</Col>
		</>
	);
};

export default Register;
