import adminApi from 'apis/admin-apis';
import ReviewRow from 'components/CustomerReviews/ReviewRow';
import OnlyHeader from 'components/Headers/OnlyHeader';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	FormGroup,
	Input,
	Row,
	Spinner,
	Table
} from 'reactstrap';
import '../../styles/CustomerReview.css';
import useAuth from 'hooks/useAuth';
import RemindModal from 'components/CustomerReviews/RemindModal';

const CustomerReview = () => {
	useAuth();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	const { authToken } = useSelector((state) => state.auth);
	const { sendRemindModal } = useSelector((state) => state.modals);

	const [customerName, setCustomerName] = useState('');
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(false);
	const [customerNotSelected, setCustomerNotSelected] = useState(false);

	useEffect(() => {
		setLoading(true);
		if (queryParams.get('customerId')) {
			const customerId = queryParams.get('customerId');
			// setCustomerId(customerId)
			adminApi
				.getCustomerById(customerId, authToken)
				.then((response) => {
					setCustomerName(response.data.customer.name);
				})
				.catch((err) => {});
			adminApi
				.customerReviews(customerId, authToken)
				.then((response) => {
					// console.log(response.data);
					setReviews(response.data.reviews);
					setLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setLoading(false);
				});
		} else {
			setLoading(false);
			setCustomerNotSelected(true);
		}

		// setLoading(false);
	}, []);

	return (
		<>
			<OnlyHeader />
			<Container fluid className="mt--7">
				<Row>
					<Col>
						{loading ? (
							<Row className="justify-content-center">
								<Spinner />
							</Row>
						) : (
							<Card className="shadow">
								<CardHeader>
									<h3>Customer Reviews {customerName}</h3>
									{customerNotSelected ? (
										<h5 className="mt-5">Customer Not Selected!</h5>
									) : reviews.length === 0 ? (
										<h5 className="mt-5">No Reviews!</h5>
									) : null}
								</CardHeader>
								<Table>
									<thead className="thead-light">
										<tr>
											<th className="col-md-6" scope="col">
												Survey Name
											</th>
											<th className="col-md-2" scope="col">
												Status
											</th>
											<th
												className="col-md-2"
												style={{ textAlign: 'center' }}
												scope="col"
											>
												Rating
											</th>
											<th className="col-md-2" scope="col" />
										</tr>
									</thead>
								</Table>
								<CardBody>
									{reviews.map((review) => {
										return (
											<ReviewRow
												key={review.reviewId}
												review={review}
												// showCustomerName
											/>
										);
									})}
								</CardBody>
							</Card>
						)}
					</Col>
				</Row>
				{sendRemindModal && <RemindModal />}
			</Container>
		</>
	);
};

export default CustomerReview;
