const { createSlice } = require('@reduxjs/toolkit');

const customersSlice = createSlice({
	name: 'customers',
	initialState: {
		customers: [],
		customersLoading: false,
		selectedCustomerId: '',
		selectedCustomer: {}
	},
	reducers: {
		setCustomer: (state, action) => {
			state.customers = action.payload;
		},
		setCustomerLoading: (state, action) => {
			state.customerLoading = action.payload;
		},
		setSelectedCustomerId: (state, action) => {
			state.selectedCustomerId = action.payload;
		},
		setSelectedCustomer: (state, action) => {
			state.selectedCustomer = action.payload;
		}
	}
});

export const { setCustomer, setCustomerLoading, setSelectedCustomerId, setSelectedCustomer } =
	customersSlice.actions;
export default customersSlice.reducer;
