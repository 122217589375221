import adminApi from 'apis/admin-apis';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	Spinner
} from 'reactstrap';
import { toggleCreateNewCustomerModal } from 'redux/modalsSlice';

const CreateCustomerModal = () => {
	const dispatch = useDispatch();
	const { createNewCustomerModal } = useSelector((state) => state.modals);
	const { authToken } = useSelector((state) => state.auth);

	const [createCustomerLoading, setCreateCustomerLoading] = useState(false);
	const [name, setName] = useState('');
	const [nameInvalid, setNameInvalid] = useState(false);

	const [email, setEmail] = useState('');
	const [emailInvalid, setEmailInvalid] = useState(false);

	const submitCreateNewCustomer = async () => {
		setCreateCustomerLoading(true);
		validateNewCustomerInput();
		if (email.trim() !== '' && name.trim() !== '') {
			adminApi
				.createCustomer({ customer: { name, email, logoLink: '' } }, authToken)
				.then((response) => {
					dispatch(toggleCreateNewCustomerModal());
					setCreateCustomerLoading(false);
				})
				.catch((err) => {
					setCreateCustomerLoading(false);
				});
		} else {
			setCreateCustomerLoading(false);
		}
	};

	const validateNewCustomerInput = () => {
		if (email.trim() === '') {
			setEmailInvalid(true);
		} else {
			setEmailInvalid(false);
		}

		if (name.trim() === '') {
			setNameInvalid(true);
		} else {
			setNameInvalid(false);
		}
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				isOpen={createNewCustomerModal}
				toggle={() => {
					dispatch(toggleCreateNewCustomerModal());
				}}
			>
				<div className="modal-header align-items-center">
					<h4 className="modal-title">Create a New Customer</h4>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => dispatch(toggleCreateNewCustomerModal())}
					>
						<span aria-hidden={true}>x</span>
					</button>
				</div>
				<ModalBody>
					<Form>
						<FormGroup>
							<Label>Name</Label>
							<Input
								type="text"
								invalid={nameInvalid}
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
							<FormFeedback>Name required!</FormFeedback>
						</FormGroup>

						<FormGroup>
							<Label>Email</Label>
							<Input
								type="email"
								invalid={emailInvalid}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
							<FormFeedback>Email required!</FormFeedback>
						</FormGroup>
					</Form>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={submitCreateNewCustomer}>
						{createCustomerLoading ? <Spinner size="xs" /> : 'Create Customer'}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default CreateCustomerModal;
