// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyCXZo1CKwYMz6yjvEIgWmwSTF3_SQsKLHc',
	authDomain: 'nps-rits.firebaseapp.com',
	projectId: 'nps-rits',
	storageBucket: 'nps-rits.appspot.com',
	messagingSenderId: '378086164496',
	appId: '1:378086164496:web:65e72ced3c96be43d1a295'
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
const auth = getAuth(firebase);
const db = getFirestore(firebase);
const storage = getStorage(firebase);

export { firebase, analytics, auth, db, storage };
