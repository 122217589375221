import InputReview from 'components/AddReviewPage/InputReview';
import { Col, Container, Row } from 'reactstrap';

import '../styles/AddReviewPage.css';
import SuccessfulRatingSubmition from 'components/AddReviewPage/SuccessfulRatingSubmition';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import publicApi from 'apis/public-apis';
import { useMediaQuery } from 'react-responsive';

const AddReviewPage = () => {
	const location = useLocation();

	const { successfulSubmit } = useSelector((state) => state.rating);
	const [isFilled, setIsFilled] = useState(false);

	const isXsScreen = useMediaQuery({ maxWidth: 575 }); // xs screen size
	const isMdScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 }); // md screen size

	useEffect(() => {
		var uniqueId = location.pathname.replace('/review/', '');
		publicApi.isReviewFilled(uniqueId).then((response) => {
			if (response.data.isReviewFilled) {
				setIsFilled(true);
			}
		});
	});

	return (
		<>
			<Container className="full-vp-width" fluid style={{ backgroundColor: '#141519' }}>
				<Row
					className="justify-content-center align-items-center"
					style={isXsScreen ? {} : { height: '100dvh' }}
					// style={{ height: '100dvh' }}
				>
					<Col className="d-flex justify-content-center" xs="11" md="10" lg="8">
						{successfulSubmit || isFilled ? (
							<SuccessfulRatingSubmition />
						) : (
							<InputReview />
						)}
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default AddReviewPage;
