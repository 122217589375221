import Panel from 'components/BasicUIElements/Panel';
import { Col, Row } from 'reactstrap';

const SuccessfulRatingSubmition = () => {
	return (
		<>
			<Row className="d-flex justify-content-center">
				<Col xs="12">
					<Panel bg="#1F2630">
						<Row>
							<Col xs="12" className="d-flex justify-content-center">
								<img
									src={
										require('../../assets/img/icons/common/feedback.png')
											.default
									}
									alt="feedback"
									width={'300px'}
								/>
							</Col>
						</Row>

						<Row className="justify-content-center mt-5">
							<h1 style={{ color: 'white' }}>Dankeschön</h1>
						</Row>

						<Row className="justify-content-center mt-4 mb-5">
							<h3 style={{ color: 'white', marginLeft: '10px', marginRight: '10px' }}>
								Wir freuen uns, dass Sie sich die Zeit genommen haben, eine
								Bewertung abzugeben. Sollten Sie jemals mehr Unterstützung
								benötigen, zögern Sie nicht, uns zu kontaktieren.
							</h3>
						</Row>
					</Panel>
				</Col>
			</Row>
		</>
	);
};

export default SuccessfulRatingSubmition;
