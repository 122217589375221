import { Col, Row } from 'reactstrap';

const Panel = ({ children, bg }) => {
	return (
		<Row>
			<Col
				xs="12"
				// className="d-flex justify-content-center align-content-center padding-10 margin-10"
				style={{ backgroundColor: bg, borderRadius: '15px' }}
			>
				{children}
			</Col>
		</Row>
	);
};

export default Panel;
