import publicApi from 'apis/public-apis';
import { toast } from 'react-toastify';
const { createSlice } = require('@reduxjs/toolkit');

const ratingSlice = createSlice({
	name: 'rating',
	initialState: {
		ratingSelected: '',
		ratingText: '',
		successfulSubmit: false,
		loading: false,
		selectedRating: {}
	},
	reducers: {
		setRatingSelected: (state, action) => {
			state.ratingSelected = action.payload;
		},
		setRatingText: (state, action) => {
			state.ratingText = action.payload;
		},
		setSuccessfulSubmit: (state, action) => {
			state.successfulSubmit = action.payload;
		},
		setLoading: (state, action) => {
			state.loading = action.payload;
		},
		setSelectedRating: (state, action) => {
			state.selectedRating = action.payload;
		}
	}
});

export const {
	setRatingSelected,
	setRatingText,
	setSuccessfulSubmit,
	setLoading,
	setSelectedRating
} = ratingSlice.actions;
export default ratingSlice.reducer;

export const submitReview = (id) => async (dispatch, getState) => {
	var ratingSelected = getState().rating.ratingSelected;
	var ratingText = getState().rating.ratingText;

	if (ratingSelected.trim() !== '') {
		// submit to DB
		publicApi
			.submitReview({ id, rating: ratingSelected, ratingText: ratingText })
			.then((response) => {
				dispatch(setSuccessfulSubmit(true));
				toast.success('Review Submitted!', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light'
				});
			})
			.catch((err) => {
				toast.error('Error in submission!', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light'
				});
			});
	} else {
		// error toast
		toast.error('Please select a rating to submit!', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light'
		});
	}
};
