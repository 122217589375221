import adminApi from 'apis/admin-apis';
import RemindModal from 'components/CustomerReviews/RemindModal';
import ReviewRow from 'components/CustomerReviews/ReviewRow';
import OnlyHeader from 'components/Headers/OnlyHeader';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { FaLess } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row, Spinner, Table } from 'reactstrap';

const AggregateReviewPage = () => {
	useAuth();

	const { authToken } = useSelector((state) => state.auth);
	const { sendRemindModal } = useSelector((state) => state.modals);

	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		adminApi
			.getAllReviews(authToken)
			.then((response) => {
				console.log(response);
				setReviews(response.data.reviews);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	return (
		<>
			<OnlyHeader />
			<Container fluid className="mt--7">
				<Row>
					<Col>
						{loading ? (
							<Row className="justify-content-center">
								<Spinner />
							</Row>
						) : (
							<Card className="shadow">
								<CardHeader>
									<h3>Aggregate Reviews</h3>
								</CardHeader>
								{/* <CardBody> */}
								<Table>
									<thead className="thead-light">
										<tr>
											<th className="col-md-3" scope="col">
												Customer Name
											</th>
											<th className="col-md-3" scope="col">
												Survey Name
											</th>
											<th className="col-md-2" scope="col">
												Status
											</th>
											<th
												className="col-md-2"
												style={{ textAlign: 'center' }}
												scope="col"
											>
												Rating
											</th>
											<th className="col-md-2" scope="col" />
										</tr>
									</thead>
								</Table>
								{reviews.map((review) => {
									return (
										<ReviewRow
											key={review.reviewId}
											review={review}
											showCustomerName
										/>
									);
								})}
								{/* </CardBody> */}
							</Card>
						)}
					</Col>
				</Row>
				{sendRemindModal && <RemindModal />}
			</Container>
		</>
	);
};

export default AggregateReviewPage;
