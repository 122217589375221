const { createSlice } = require('@reduxjs/toolkit');
const { deleteApp } = require('firebase/app');

const modalsSlice = createSlice({
	name: 'modals',
	initialState: {
		createNewCustomerModal: false,
		updateCustomerModal: false,
		deleteCustomerModal: false,
		sendReviewToCustomerModal: false,
		sendRemindModal: false
	},
	reducers: {
		toggleCreateNewCustomerModal: (state) => {
			state.createNewCustomerModal = !state.createNewCustomerModal;
		},
		toggleUpdateCustomerModal: (state) => {
			state.updateCustomerModal = !state.updateCustomerModal;
		},
		toggleDeleteCustomerModal: (state) => {
			state.deleteCustomerModal = !state.deleteCustomerModal;
		},
		toggleSendReviewToCustomerModal: (state) => {
			state.sendReviewToCustomerModal = !state.sendReviewToCustomerModal;
		},
		toggleSendRemindModal: (state) => {
			state.sendRemindModal = !state.sendRemindModal;
		}
	}
});

export default modalsSlice.reducer;
export const {
	toggleCreateNewCustomerModal,
	toggleDeleteCustomerModal,
	toggleSendReviewToCustomerModal,
	toggleUpdateCustomerModal,
	toggleSendRemindModal
} = modalsSlice.actions;
