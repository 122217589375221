import adminApi from 'apis/admin-apis';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
	Button,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	Spinner
} from 'reactstrap';
import { toggleSendRemindModal } from 'redux/modalsSlice';

const RemindModal = () => {
	const dispatch = useDispatch();
	const { sendRemindModal } = useSelector((state) => state.modals);
	const { selectedRating } = useSelector((state) => state.rating);
	const { authToken } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);

	const [reminderBody, setReminderBody] = useState(
		'Wir hoffen, dass Sie diese Nachricht gut finden. Ihre Meinung ist uns sehr wichtig, und wir möchten Sie gerne an unsere laufende Umfrage erinnern. Ihr Feedback ist für uns sehr wichtig, da wir ständig bemüht sind, unsere Produkte und Dienstleistungen zu verbessern. <br/>'
	);

	const sendRemindEmail = async () => {
		setLoading(true);
		adminApi
			.sendReminderEmail(
				{
					email: selectedRating.email,
					customerId: selectedRating.customerId,
					reviewId: selectedRating.reviewId,
					emailBodyText: reminderBody
				},
				authToken
			)
			.then((response) => {
				toast.success('Reminder Sent!', {
					position: 'bottom-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light'
				});
				setLoading(false);
				dispatch(toggleSendRemindModal());
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				isOpen={sendRemindModal}
				toggle={() => {
					dispatch(toggleSendRemindModal());
				}}
			>
				<div className="modal-header align-items-center">
					<h4 className="modal-title">Send Reminder</h4>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => dispatch(toggleSendRemindModal())}
					>
						<span aria-hidden={true}>x</span>
					</button>
				</div>
				<ModalBody>
					<FormGroup>
						<Label>Email Body Text</Label>
						<Input
							type="textarea"
							style={{ height: '150px' }}
							value={reminderBody}
							onChange={(e) => setReminderBody(e.target.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={sendRemindEmail} disabled={loading}>
						{loading ? <Spinner size={'sm'} /> : 'Send'}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default RemindModal;
