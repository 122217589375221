import adminApi from 'apis/admin-apis';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { toggleDeleteCustomerModal } from 'redux/modalsSlice';

const DeleteCustomerModal = () => {
	const dispatch = useDispatch();
	const { deleteCustomerModal } = useSelector((state) => state.modals);
	const { selectedCustomer, selectedCustomerId } = useSelector((state) => state.customers);
	const { authToken } = useSelector((state) => state.auth);

	const [loading, setLoading] = useState(false);

	const handleDeleteCustomer = () => {
		setLoading(true);

		adminApi
			.deleteCustomer(selectedCustomerId, authToken)
			.then((response) => {
				setLoading(false);
				dispatch(toggleDeleteCustomerModal());
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	return (
		<>
			<Modal
				className="modal-dialog-centered"
				isOpen={deleteCustomerModal}
				toggle={() => {
					dispatch(toggleDeleteCustomerModal());
				}}
			>
				<div className="modal-header align-items-center">
					<h4 className="modal-title">
						Delete Customer {selectedCustomer && selectedCustomer.name} ?
					</h4>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => dispatch(toggleDeleteCustomerModal())}
					>
						<span aria-hidden={true}>x</span>
					</button>
				</div>
				<ModalBody>
					<Row>
						<Col className="d-flex" style={{ justifyContent: 'space-around' }}>
							<Button
								color="primary"
								onClick={handleDeleteCustomer}
								disabled={loading}
							>
								YES
							</Button>
							{loading && <Spinner size="sm" />}

							<Button
								color="primary"
								onClick={() => dispatch(toggleDeleteCustomerModal())}
								disabled={loading}
							>
								NO
							</Button>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</Modal>
		</>
	);
};

export default DeleteCustomerModal;
