import adminApi from 'apis/admin-apis';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { toggleSendRemindModal } from 'redux/modalsSlice';
import { setSelectedRating } from 'redux/ratingSlice';

const ReviewRow = ({ review, showCustomerName = false }) => {
	const dispatch = useDispatch();
	const { authToken } = useSelector((state) => state.auth);
	const [customerName, setCustomerName] = useState('');

	const [isExpanded, setIsExpanded] = useState(false);

	useEffect(() => {
		if (showCustomerName) {
			adminApi.getCustomerById(review.customerId, authToken).then((response) => {
				setCustomerName(response.data.customer.name);
			});
		}
	}, []);

	return (
		<>
			<Row
				className={`mt-4 review-row align-items-center p-1 ${
					isExpanded && 'review-row-focus'
				}`}
			>
				{showCustomerName && (
					<Col
						xs="12"
						md="3"
						className="customer-name pl-5"
						onClick={() => setIsExpanded(!isExpanded)}
					>
						{customerName}
					</Col>
				)}
				<Col
					xs="12"
					md={`${showCustomerName ? '3' : '6'}`}
					onClick={() => setIsExpanded(!isExpanded)}
					className="survey-name"
				>
					{review.name}
				</Col>
				<Col xs="3" md="2" onClick={() => setIsExpanded(!isExpanded)} className="status">
					<div
						style={{
							backgroundColor: review.isFilled ? 'green' : 'red',
							color: 'white',
							padding: '10px',
							borderRadius: '5px',
							maxWidth: '100px',
							textAlign: 'center',
							fontSize: '14px'
						}}
					>
						{review.isFilled ? 'DONE' : 'PENDING'}
					</div>
				</Col>
				<Col
					xs="4"
					md="2"
					style={{ textAlign: 'center' }}
					onClick={() => setIsExpanded(!isExpanded)}
					className="rating"
				>
					{review.isFilled ? (
						<>
							<span
								style={{
									color:
										parseInt(review.rating) >= 8
											? 'green'
											: parseInt(review.rating) >= 5
											? '#d5d500'
											: 'red',
									fontSize: '30px',
									fontWeight: 'bold'
								}}
							>
								{review.rating}
							</span>
							/10
						</>
					) : (
						'-'
					)}
				</Col>
				<Col xs="5" md="2" className="d-flex justify-content-end">
					{review.isFilled || (
						<Button
							color="primary"
							onClick={() => {
								dispatch(setSelectedRating(review));
								dispatch(toggleSendRemindModal());
							}}
						>
							<i className="ni ni-time-alarm" />
						</Button>
					)}
					<Button
						onClick={() => setIsExpanded(!isExpanded)}
						disabled={review.ratingText.trim() === ''}
					>
						{isExpanded ? (
							<i className="ni ni-fat-delete" />
						) : (
							<i className="ni ni-fat-add" />
						)}
					</Button>
				</Col>
			</Row>
			{isExpanded && (
				<Row
					className="justify-content-center pt-2"
					style={{ backgroundColor: 'rgba(94, 114, 228, 0.15)' }}
				>
					<Col xs="11">{review.ratingText}</Col>
				</Row>
			)}
		</>
	);
};

export default ReviewRow;
